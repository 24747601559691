// @ts-strict-ignore
import * as Sentry from "@sentry/sveltekit";
import { PUBLIC_SENTRY_ENVIRONMENT } from "$env/static/public";

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
  dsn: "https://c98d946d5783e1004c97c3d42bca2eac@o30383.ingest.us.sentry.io/4507606660939776",
  tracesSampleRate: 0.02,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.2,
  integrations: [Sentry.replayIntegration()],
  enabled: PUBLIC_SENTRY_ENVIRONMENT === "production",
  allowUrls: [/https?:\/\/\S*\.?carta\.com/],
  environment: `${PUBLIC_SENTRY_ENVIRONMENT}-client`,
  beforeSend: (event) => {
    if (event.exception?.values?.length) {
      const hasUnhandledRejectionError = event.exception.values.filter(
        (exception) =>
          exception.type === "UnhandledRejection" &&
          exception.value ===
            "Non-Error promise rejection captured with value: delay_connect",
      );
      if (hasUnhandledRejectionError.length) {
        const googleAnalyticsLoaded = !!window.dataLayer?.length;
        const cookieProLoaded = !!window.dataLayer?.filter(
          (data) => data.event === "OneTrustLoaded",
        ).length;
        const munchkinLoaded = !!window.Munchkin;
        const mutintyLoaded = !!window.mutiny?.visitor?.token;
        const bizibleLoaded = !!window.Bizible;

        event.tags = {
          ...event.tags,
          googleAnalyticsLoaded,
          cookieProLoaded,
          munchkinLoaded,
          mutintyLoaded,
          bizibleLoaded,
        };
      }
    }
    return event;
  },
});

export const handleError = Sentry.handleErrorWithSentry();
